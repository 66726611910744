import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import DropdownMenu from "./DropdownMenu/DropdownMenu"
import { StaticImage } from "gatsby-plugin-image"
import LanguageSelector from "./LanguageSelector/LanguageSelector"
import SlidingMenu, { SlidingMenuButton } from "./SlidingMenu/SlidingMenu"
//@ts-ignore
import AOS from "aos";
import "aos/dist/aos.css";
import "./Header.scss"

const Header = () => {
  const [openSlidingMenu, setOpenSlidingMenu] = useState(false)
  const handleClick = () => setOpenSlidingMenu(!openSlidingMenu)
  const [navbar, setNavbar] = useState(false)

  useEffect(() => {
    typeof window !== "undefined" && window.addEventListener("scroll", changeBackground);

    AOS.init({
      duration: 1200,
    });

  }, [])

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  return (
    <>
      <header className="header">
        <div
          className={
            navbar
              ? "header-navbar header-navbar--fixed"
              : "header-navbar"
          }
        >
          <div className="container-xxl">
            <div className="header-container">
              <Link
                className="header-logo"
                to="/"
              >
                <StaticImage
                  className="black"
                  src="../../images/root/VRT-LOGO-WHITE-SHORT.png"
                  alt=""
                  loading="lazy"
                  width={91}
                  height={30}
                  placeholder="none"
                />
              </Link>

              <DropdownMenu />

              <LanguageSelector />

              <SlidingMenuButton handleClick={handleClick} />
            </div>
          </div>
        </div>
      </header>

      <SlidingMenu
        open={openSlidingMenu}
        handleClickClose={handleClick}
      />
    </>
  );
};

export default Header;

