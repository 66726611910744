import React from "react"
import SocialShare from "./Social.data.json"
import "./Social.scss"

const Social = () => {
  return (
    <div className="social">
      {SocialShare.map((icon, i) => (
        <a
          className={`social__icon ${icon.iconClass}`}
          href={icon.link}
          target="_blank"
          rel="noopener noreferrer"
          key={i}
        >
          <i className={icon.iconName}></i>
        </a>
      ))}
    </div>
  )
}

export default Social;
