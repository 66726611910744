import React from "react"
import Social from "../Social/Social"
import "./CopyRight.scss"

const CopyRight = () => {
  return (
    <div className="row copy-right">
      <div className="col-12 col-lg">

      </div>
      <div className="col-12 col-md">
        <p className="copy-right__company">
          ©{new Date().getFullYear()}{" "}
          Wszelkie prawa zastrzeżone
        </p>
      </div>
      <div className="col-12 col-lg text-md-center text-lg-end">
        <Social />
      </div>
    </div>
  )
}

export default CopyRight;
