import React from "react"

const LanguageSelector = () => {

  function handleClick(event: any): void {
    console.log("Click")
  }

  return (
    <>
      {/* TODO: Rename search class */}
      <div
        className={"ptf-navbar-search"}
      >
        <div
          className="ptf-navbar-search__toggle"
          onClick={handleClick}
        >
        </div>
      </div>
    </>
  );
};

export default LanguageSelector;

