import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./FooterInformation.scss"

const logo = "../../../images/root/VRT-LOGO-WHITE-SHORT.png"

const FooterInformation = () => {
  return (
    <div className="footer-information">
      <StaticImage
        src={logo}
        alt=""
        loading="lazy"
        width={80}
        height={26}
      />
      <div className="footer-information__title">
        VRTechnology sp. z o.o.
      </div>


      <p className="footer-information__address">
        ul. Łużycka 16 <br></br>
        44-100 / Gliwice / Polska <br></br>
        NIP 6340195129 <br></br>
        +48 728 979 066 <br></br>
      </p>

      <a
        className="footer-information__email"
        href="office@vrtechnology.pl"
      >
        office@vrtechnology.pl
      </a>
    </div>
  )
}

export default FooterInformation
