import React from "react";
import Social from "../../../Social/Social";
import "./MobileMenuFooter.scss"

const MobileMenuFooter = () => {
  return (

    <div className="mobile-menu-footer">
      <p className="mobile-menu-footer-copyright">
        @{new Date().getFullYear()} Wszelkie prawa zastrzeżone{" "}
        <br />
      </p>
      <Social />
    </div>
  )
}

export default MobileMenuFooter;
