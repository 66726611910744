import React from "react"
import MobileMenu from "../MobileMenu/MobileMenu"
import "./SlidingMenu.types"
import "./SlidingMenu.scss"

const SlidingMenu = (
  { open, handleClickClose }: SlidingMenuProps
) => {
  return (
    <div
      className={
        open ? "sliding-menu is-open" : "sliding-menu "
      }
    >
      <div className="sliding-menu__header">
        <div className="sliding-menu-language-switcher">
          <a className="active" href="#">
            Pol
          </a>
          {/* <a href="#">Eng</a> */}
          {/* <a href="#">Ger</a> */}
        </div>
        <span
          className="sliding-menu-toggle"
          onClick={handleClickClose}
        >
          <i className="sliding-menu-close lnir lnir-close"></i>
        </span>
      </div>

      <MobileMenu
        onClick={handleClickClose}
      />
    </div>
  )
}
export default SlidingMenu

export const SlidingMenuButton = (
  { handleClick }: SlidingMenuButtonProps
) => {
  return (
    <div
      className="header_hamburger"
      onClick={handleClick}
    >
      <i className="lnir  lnir-menu-alt-5" />
    </div>
  )
}

