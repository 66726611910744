import "../scss/main.scss"

import React, { useEffect } from "react"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { clarity } from 'react-microsoft-clarity'

const MainLayout = ({ children }: any) => {

  useEffect(() => {
    process.env.GATSBY_API_CLARITY_ID &&
      clarity.init(process.env.GATSBY_API_CLARITY_ID);
  }, [])

  return (
    <>
      <div className="ptf-site-wrapper animsition  ptf-is--home-dark">
        <Header />

        <main>
          {children}
        </main>

        <Footer />
      </div>
    </>
  )
}

export default MainLayout