import React from "react"
import data from "./Footer.data.json"
import CopyRight from "../CopyRight/CopyRight"
import FooterMenu from "./FooterMenu/FooterMenu"
import FooterInformation from "./FooterInformation/FooterInformation"
import "./Footer.scss"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-xxl">
        <div className="section_space" />

        <div className="footer__top">
          <div className="row">
            <div className="col-12 col-xl-6">
              <FooterInformation />
            </div>

            <div className="col-12 col-md-6 col-xl-3">
              <FooterMenu
                title={"Działalność"}
                data={data.linkList}
              />
            </div>

            <div className="col-12 col-md-6 col-xl-3">
              <FooterMenu
                title={"Strony"}
                data={data.productList}
              />
            </div>
          </div>
        </div>

        <div className="section_space" />

        <div className="footer__bottom">
          <CopyRight />
        </div>
      </div>
    </footer>
  )
}

export default Footer
