import React from "react"
import { useLocation } from '@reach/router'
import { Link } from "gatsby"
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar"
import MobileMenuFooter from "./MobileMenuFooter/MobileMenuFooter"
import data from "../Menu.data.json"
import "react-pro-sidebar/dist/css/styles.css"
import "./MobileMenu.scss"

type MobileMenuProps = {
  onClick: () => void
}

const MobileMenu = ({
  onClick
}: MobileMenuProps) => {
  const location = useLocation()

  return (
    <>
      <div className="mobile-menu">
        <ProSidebar>
          <SidebarContent>
            <Menu className="mobile-menu-sidebar">
              {data.map((item, i) =>
                item?.dropDownItems
                  ?
                  <SubMenu
                    title={item.name}
                    key={i}
                    placeholder={undefined}
                    defaultOpen={true}
                  >
                    {item?.dropDownItems?.map((val, i) => (
                      <MenuItem
                        key={i}
                        active={true}
                      >
                        {/* <Link to={val.routerPath}>{val.name}</Link> */}
                        <Link to={val.path} onClick={onClick}>
                          {val.name}
                        </Link>
                      </MenuItem>
                    ))}
                  </SubMenu>
                  :
                  <MenuItem
                    title={item.name}
                    key={i}
                    className={item.path === location.pathname ? "mobile-menu-sidebar__current" : ""}
                  >
                    <Link to={item.path} onClick={onClick}>
                      {item.name}
                    </Link>
                  </MenuItem>
              )}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>

      <MobileMenuFooter />
    </>
  );
};

export default MobileMenu;
