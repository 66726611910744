import React from "react"
import { useLocation } from '@reach/router'
import data from "../Menu.data.json"
import DropdownMenuItem from "./DropdownMenuItem/DropdownMenuItem"
import "./DropdownMenu.scss"

const DropdownMenu = () => {
  const location = useLocation()

  return (
    // TODO rename class
    <nav className="dropdownmenu">
      <ul className="dropdownmenu-list">
        {
          data.map((item, i) => (
            <DropdownMenuItem
              id={i}
              key={i}
              name={item.name}
              path={item.path}
              current={item.path === location.pathname || (item.path === undefined && (location.pathname == "/br/" || location.pathname.includes("activity")))}
              dropDownItems={item.dropDownItems}
            />
          ))
        }
      </ul>
    </nav>
  );
};

export default DropdownMenu;
