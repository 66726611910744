import { Link } from "gatsby"
import React from "react"
import "./FooterMenu.scss"

const FooterMenu = (props: FooterMenuProps) => {
  return (
    <div className="footer-menu">
      <h4 className="footer-menu__title">
        {props.title}
      </h4>
      <div className="footer-menu__link ">
        <ul>
          {props.data.map((val, i) => (
            <li key={i}>
              <Link to={val.link}>{val.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default FooterMenu
