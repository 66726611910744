import React from "react"
import { Link } from "gatsby"
import { DropdownMenuItemProps } from "./DropdownMenuItem.types"
import "./DropdownMenuItem.scss"

const DropdownMenuItem = (props: DropdownMenuItemProps) => {
  // TODO fix this class creator
  let className = "dropdown-menu-item "
  className += props.current && "current-menu-item"

  return (
    // <p className="dupa">
    <li className={className} key={props.id}>
      {
        props.path
          ?
          <Link to={props.path}>
            <span>{props.name}</span>
          </Link>
          :
          <a>
            <span>{props.name}</span>
          </a>

      }

      {
        props.dropDownItems &&
        <ul className="sub-menu">
          {props?.dropDownItems?.map((val, i) => (
            <li key={i} className={val.path === "/#" ? "" : "sub-menu--disabled"}>
              {/* <Link to={val.routerPath}> */}
              <Link to={val.path}>
                {" "}
                <span>{val.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      }
    </li>
    // </p>
  )
}

export default DropdownMenuItem
